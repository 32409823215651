import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import * as d3Collection from "d3-collection";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { default as MuiList } from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import List from "../../List";
import Filter from "../../Filter";

import { CatalogStore, FilterStore } from "stores";
import MarkeReducer from "../MarkeFilter/MarkeReducer";
import MarkeIndicator from "../MarkeFilter/MarkeIndicator";

import ModellReducer from "../ModellFilter/ModellReducer";
import ModellIndicator from "../ModellFilter/ModellIndicator";

import { SearchField } from "../AusstattungFilter";

interface iMarkeModellFilter {
  disabled: boolean;
}

const MarkeModellFilter: React.FC<iMarkeModellFilter> = ({
  disabled = false,
}): JSX.Element | null => {
  const [search, setSearch] = React.useState("");

  const { formatMessage: t } = useIntl();

  const {
    state: {
      filter: { marke: markeFilter, modell: modellFilter },
    },
    dispatch,
  } = React.useContext(FilterStore);

  const {
    state: {
      entities: { marke: markeCollection, modell: modellCollection },
    },
  } = React.useContext(CatalogStore);

  React.useEffect(
    () => {
      dispatch({
        type: "ADD_REDUCER",
        payload: { key: "marke", value: MarkeReducer },
      });
      dispatch({
        type: "ADD_REDUCER",
        payload: { key: "modell", value: ModellReducer },
      });
      dispatch({
        type: "ADD_INDICATOR",
        payload: { key: "marke", value: MarkeIndicator },
      });
      dispatch({
        type: "ADD_INDICATOR",
        payload: { key: "modell", value: ModellIndicator },
      });
      dispatch({
        type: "INIT_FILTER",
        payload: { marke: [] },
      });
      dispatch({
        type: "INIT_FILTER",
        payload: { modell: [] },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  if (!(markeCollection && modellCollection && markeFilter && modellFilter))
    return null;

  const toggleModell = (value: any) => {
    const checkedModel =
      value.length > modellFilter.length
        ? value.filter((n: any) => !modellFilter.includes(n))[0]
        : modellFilter.filter((n: any) => !value.includes(n))[0];

    const modelMarke = modellCollection[checkedModel].marke;
    const markeIsChecked = markeFilter.includes(modelMarke);

    const modelsOfMarke = Object.values(modellCollection)
      .filter(({ marke, id }: any) => marke === modelMarke)
      .map(({ id }: any) => id);

    const newFilter = [
      {
        key: "modell",
        value,
      },
    ];

    if (markeIsChecked) {
      const newMarkesFilter = markeFilter.filter(
        (marke: any) => marke !== modelMarke
      );

      newFilter.push({
        key: "marke",
        value: newMarkesFilter,
      });
    } else {
      const allModelsAreChecked = !modelsOfMarke.filter(
        (id) => !value.includes(id)
      ).length;
      if (allModelsAreChecked) {
        toggleMarke(modelMarke);
        return;
      }
    }

    setFilterHandler(newFilter);
  };

  const toggleMarke = (optionValue: number) => {
    const markeIsChecked = markeFilter.includes(optionValue);
    let newChecked;
    if (markeIsChecked) {
      newChecked = markeFilter.filter((id: number) => id !== optionValue);
    } else {
      newChecked = [...markeFilter, optionValue];
    }

    const newModelsFilter = modellFilter.filter(
      (id: number) => modellCollection[id].marke !== optionValue
    );

    const newFilter = [
      {
        key: "marke",
        value: newChecked,
      },
      {
        key: "modell",
        value: newModelsFilter,
      },
    ];

    setFilterHandler(newFilter);
  };

  const changeSearch = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearch(event.target.value);
  };

  const setFilterHandler = (updates: any[]) => {
    updates.map(({ key, value }) => {
      return dispatch({ type: "UPDATE_FILTER", payload: { [key]: value } });
    });
    return true;
  };

  const selectAll = () => {
    setFilterHandler([
      {
        key: "marke",
        value: [],
      },
      {
        key: "modell",
        value: [],
      },
    ]);
  };

  var options = d3Collection
    .nest()
    .key(({ marke }: any) => marke)
    .sortKeys((markeIdA: string | number, markeIdB: string | number) =>
      markeCollection[markeIdA].name > markeCollection[markeIdB].name ? 1 : -1
    )
    .sortValues(({ name: nameA }: any, { name: nameB }: any) => {
      return nameA > nameB ? 1 : -1;
    })
    .entries(Object.values(modellCollection));

  const searchedModells = Object.values(modellCollection)
    .filter(
      ({ name }: any) =>
        search.length > 1 &&
        name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
    )
    .map(({ id, name }: any) => ({ value: id, title: name }));

  const searchedMarkes = Object.values(markeCollection)
    .filter(
      ({ name }: any) =>
        search.length > 1 &&
        name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
    )
    .map(({ id, name }: any) => ({ value: id, title: name }));

  const Component = (
    <Box width={1}>
      <MuiList>
        <SearchField
          value={search}
          onChange={changeSearch}
          label={t({ id: "common.search", defaultMessage: "Suche" })}
        />
        <List
          value={markeFilter || []}
          options={searchedMarkes}
          setValueHandler={(v: any) => {
            const diff = difference(v, markeFilter);
            toggleMarke(diff[0]);
          }}
        />
        <List
          value={modellFilter || []}
          options={searchedModells}
          setValueHandler={toggleModell}
        />
        <Divider />
        <ListItem
          id="filter-marke-model-filter-all"
          key="all"
          role={undefined}
          dense
          button
          onClick={() => selectAll()}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <Checkbox
              color="primary"
              edge="start"
              checked={!(markeFilter.length || modellFilter.length)}
              tabIndex={-1}
              inputProps={{ "aria-labelledby": "checkbox-list-label-all" }}
            />
          </ListItemIcon>
          <ListItemText
            className="list-item-text"
            primary="Alle"
            primaryTypographyProps={{
              variant: "body1",
              component: "span",
            }}
          />
        </ListItem>
      </MuiList>
      <Divider />
      <MuiList>
        {options.map(({ key, values: modellCollectionOfMarke }: any) => {
          const markeId = parseInt(key);
          const markeName = markeCollection[markeId].name;

          const parsedOptions = modellCollectionOfMarke.map(
            ({ marke, name, id }: any) => ({
              title: name,
              value: id,
              marke,
            })
          );

          const markeIsChecked = markeFilter.includes(markeId);
          const oneModellOfMarkeIsChecked =
            (parsedOptions.find(({ value }: any) =>
              modellFilter.includes(value)
            ) &&
              true) ||
            false;
          return (
            <Marke
              key={markeId}
              {...{
                toggleMarke,
                markeName,
                markeId,
                setValueHandler: toggleModell,
                parsedOptions,
                value: modellFilter,
                checked: markeIsChecked,
                indeterminate: oneModellOfMarkeIsChecked,
                color:
                  markeFilter.length || modellFilter.length
                    ? "inherit"
                    : undefined,
              }}
            />
          );
        })}
      </MuiList>
    </Box>
  );

  return (
    <Filter
      component={Component}
      disabled={disabled}
      title={
        <FormattedMessage
          id="filter.marke_modell.label"
          defaultMessage="Marke"
        />
      }
    />
  );
};

interface IMarke {
  markeName: string;
  markeId: string | number;
  setMarkeHandler?: any;
  setValueHandler: any;
  parsedOptions: any;
  value: any;
  toggleMarke: any;
  checked: boolean;
  indeterminate: boolean;
  color?: string;
}

const Marke: React.FC<IMarke> = ({
  markeName,
  markeId,
  setMarkeHandler,
  setValueHandler,
  parsedOptions,
  value,
  toggleMarke,
  checked = false,
  indeterminate = false,
  color = "#BC1018",
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <React.Fragment>
      <ListItem
        id={`filter-marke-${markeName}-all`}
        key={`filter-marke-${markeId}-all`}
        role={undefined}
        dense
        button
      >
        <ListItemIcon style={{ minWidth: 30 }}>
          <Checkbox
            color="primary"
            checked={checked}
            indeterminate={indeterminate}
            edge="start"
            style={{ color: checked || indeterminate ? "#BC1018" : color }}
            tabIndex={-1}
            inputProps={{
              "aria-labelledby": "checkbox-list-label-all",
            }}
            onClick={() => toggleMarke(markeId)}
          />
        </ListItemIcon>
        <ListItemText
          id="checkbox-list-label-all"
          primary={markeName}
          primaryTypographyProps={{
            variant: "body1",
            component: "span",
          }}
          onClick={() => toggleOpen()}
        />
        {open ? (
          <ExpandLess fontSize="small" onClick={() => toggleOpen()} />
        ) : (
          <ExpandMore fontSize="small" onClick={() => toggleOpen()} />
        )}
      </ListItem>

      <Collapse style={{ backgroundColor: "#FAFAFA" }} in={open} unmountOnExit>
        <Box ml={2}>
          <List
            value={value}
            options={parsedOptions}
            setValueHandler={setValueHandler}
            color={checked ? "#BC1018" : "inherit"}
          />
        </Box>
      </Collapse>
    </React.Fragment>
  );
};

const difference = (arr1: any, arr2: any) =>
  arr1
    .filter((x: any) => !arr2.includes(x))
    .concat(arr2.filter((x: any) => !arr1.includes(x)));

export default MarkeModellFilter;
