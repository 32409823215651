import React from "react";

import LinkIcon from "@mui/icons-material/Link";
import IconButton from "@mui/material/IconButton";

import { encrypt } from "./helper";

interface iShare {
  text: string;
}

const Share: React.FC<iShare> = ({
  text = "alpha,beta,gammaalpha,beta,gammaalpha,beta,gammaalpha,beta,gammaalpha,beta,gamma",
}): JSX.Element | null => {
  const encrypted = encrypt(text);
  if (typeof encrypted !== "string") return null;

  const copyToClipboard = () => {
    const url = "https://api.carbando.eu/affiliate?id=" + encrypted;
    navigator.clipboard.writeText(url).then(
      () => {
        console.log("Link kopiert: " + url);
        /* Resolved - text copied to clipboard successfully */
      },
      () => {
        console.error("Failed to copy");
        /* Rejected - text failed to copy to the clipboard */
      }
    );
  };
  return (
    <IconButton
      color="info"
      size="small"
      aria-label="Link Kopieren"
      title="Link Kopieren"
      onClick={() => copyToClipboard()}
    >
      <LinkIcon />
    </IconButton>
  );
};

export default Share;
