import { string, object } from "yup";

const RegistrationSchema = object().shape({
  gender: string().required(),
  firstName: string().required(),
  lastName: string().required(),
  birthName: string(),

  dateOfBirth: string().required(),
  placeOfBirth: string().required(),
  nationality: string().required(),
  contact: object().shape({
    phone: string()
      .required()
      .matches(
        /^\+491(51|60|70|71|75|52|62|72|73|74|57|63|77|78|59|76|79)\d{7}\d*$/
      ),
    email: string().required().email(),
  }),

  address: object().shape({
    street: string().required(),
    houseNumber: string().required(),
    additionalAddress: string(),
    zipCode: string().required(),
    city: string().required(),
    country: string().required(),
    residentSince: string().required(),
  }),

  previousAddress: object().when("address.residentSince", {
    is: (val) => {
      if (!val) return false;
      const today = new Date();
      const minimumResidence = 3 * 365 * 24 * 60 * 60 * 1000;
      if (val.length > 0) {
        const movedIn = new Date(val);
        if (today - movedIn < minimumResidence) {
          return true;
        } else {
          return false;
        }
      }
    },
    then: (RegistrationSchema) =>
      RegistrationSchema.shape({
        street: string().required(),
        houseNumber: string().required(),
        additionalAddress: string(),
        zipCode: string().required(),
        city: string().required(),
        country: string().required(),
      }),
  }),
});

export default RegistrationSchema;
