import React from "react";
import { FormattedMessage } from "react-intl";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

interface ICaptcha {
  resolveHandler: any;
  max?: number;
}

const Captcha: React.FC<ICaptcha> = ({
  resolveHandler = (result: boolean) => console.log(result),
  max = 100,
}): JSX.Element | null => {
  const [number1, setNumber1] = React.useState(0);
  const [number2, setNumber2] = React.useState(0);
  const [question, setQuestion] = React.useState("");

  const shuffle = () => {
    const n1 = randomNaturalNumber(max);
    setNumber1(n1);
    setNumber2(randomNaturalNumber(max, n1));
    setQuestion((Math.random() > 0.5 && "gt") || "lt");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => shuffle(), []);

  const challenge = (which: string) => {
    let result;
    switch (question) {
      case "gt":
        result =
          (which === "number1" && number1 > number2) ||
          (which === "number2" && number1 < number2);
        break;
      case "lt":
        result =
          (which === "number1" && number1 < number2) ||
          (which === "number2" && number1 > number2);
    }
    if (result) {
      resolveHandler(result);
    } else {
      shuffle();
    }
  };

  if (!number1) return null;

  return (
    <Box
      sx={{
        typography: "h6",
        display: "flex",
        alignItems: "center",
        gap: 2,
        p: 2,
        border: 1,
        userSelect: "none",
      }}
    >
      <FormattedMessage
        id={`captcha.math.${question}`}
        defaultMessage="whatever"
      />
      <Avatar
        variant="square"
        onClick={() => challenge("number1")}
        sx={{
          color: "black",
          cursor: "pointer",
          transition: "all 0.2s ease",
          "&::after": {
            content: `"${number1}"`,
          },
          "&:hover": { backgroundColor: "green", color: "white" },
        }}
      >
        <div />
      </Avatar>
      <FormattedMessage id="common.or" defaultMessage="oder" />
      <Avatar
        variant="square"
        color="secondary"
        onClick={() => challenge("number2")}
        sx={{
          color: "black",
          cursor: "pointer",
          transition: "all 0.2s ease",
          "&::after": {
            content: `"${number2}"`,
          },
          "&:hover": { backgroundColor: "green", color: "white" },
        }}
      >
        <div />
      </Avatar>
    </Box>
  );
};

interface IrandomNaturalNumber {
  (max: number, not?: number): number;
}

const randomNaturalNumber: IrandomNaturalNumber = (max, not = 0) => {
  const factor = Math.random();
  let intermediate = Math.floor(factor * max) + 1;
  if (not === intermediate) intermediate += 1;
  return intermediate;
};
export default Captcha;
