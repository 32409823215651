import React from "react";
import { extent } from "d3-array";
import { FormattedMessage } from "react-intl";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { TVehicle } from "types";
import { RunTime, Deposit, Mileage } from "pages/Catalog/containers";

import { vehicle as defaultVehicle } from "fixtures";
import Detail from "./Detail";

const defaultFilter = {
  type: "buy",
  deposit: 0,
  mileage: 2000,
  rate: 1500,
  runTime: 36,
};

interface IFinance {
  paperProps?: any;
  vehicle: TVehicle;
  filter: any;
  setFilter: any;
  financeRate?: any;
}

const Finance: React.FC<IFinance> = ({
  paperProps = { sx: { p: 2 } },
  vehicle = defaultVehicle,
  filter = defaultFilter,
  setFilter = () => null,
  financeRate,
}): JSX.Element | null => {
  const [ranges, setRanges]: [any, any] = React.useState();
  // Statistics
  React.useEffect(
    () => {
      if (!vehicle) return;

      const ranges = new Map();
      if (vehicle.finance) {
        const creditParameters = {
          runtime: extent(
            vehicle.finance
              .map((e: any) => (e.credit ? e.runtime : null))
              .filter((e: number) => !!e)
          ),
        };

        creditParameters.runtime.length > 0 &&
          !!creditParameters.runtime[0] &&
          ranges.set("credit", creditParameters);

        const balloonParameters = {
          runtime: extent(
            vehicle.finance
              .map((e: any) => (e.balloon ? e.runtime : null))
              .filter((e: number) => !!e)
          ),
          mileage: extent(
            vehicle.finance
              .map((e: any) =>
                e.balloon ? e.balloon.map((e: any) => e[0]) : null
              )
              .flat()
          ),
        };

        balloonParameters.runtime.length > 0 &&
          !!balloonParameters.runtime[0] &&
          ranges.set("balloon", balloonParameters);

        const leasingParameters = {
          runtime: extent(
            vehicle.finance
              .map((e: any) => (e.leasing ? e.runtime : null))
              .filter((e: number) => !!e)
          ),
          mileage: extent(
            vehicle.finance
              .map((e: any) =>
                e.leasing ? e.leasing.map((e: any) => e[0]) : null
              )
              .flat()
          ),
        };

        leasingParameters.runtime.length > 0 &&
          !!leasingParameters.runtime[0] &&
          ranges.set("leasing", leasingParameters);
      }
      setRanges(ranges);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vehicle]
  );

  const changeType = (
    event: React.SyntheticEvent<Element, Event>,
    value: any
  ) => {
    const newFilter = { ...filter, type: value };
    if (ranges.has(value) && ranges.get(value).runtime[0] >= filter.runTime)
      newFilter.runTime = ranges.get(value).runtime[0];
    if (ranges.has(value) && ranges.get(value).runtime[1] <= filter.runTime)
      newFilter.runTime = ranges.get(value).runtime[1];
    if (
      ranges.has(value) &&
      ranges.get(value).mileage &&
      ranges.get(value).mileage[0] >= filter.mileage
    )
      newFilter.mileage = ranges.get(value).mileage[1];
    if (
      ranges.has(value) &&
      ranges.get(value).mileage &&
      ranges.get(value).mileage[1] <= filter.mileage
    )
      newFilter.mileage = ranges.get(value).mileage[1];
    setFilter(newFilter);
  };

  const changeValue = (what: any, newValue: any) => {
    const newValues = { ...filter, [what]: newValue };
    setFilter(newValues);
  };

  if (!ranges) return null;
  return (
    <Paper variant="outlined" {...paperProps}>
      <Typography variant="h5">
        <FormattedMessage
          id="pages.detail.finance.label"
          defaultMessage="Finanzierung"
        />
      </Typography>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          lg={3}
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tabs
            value={filter.type}
            orientation="vertical"
            variant="scrollable"
            onChange={changeType}
          >
            <Tab
              label={
                <FormattedMessage
                  id="filter.financial.type.options.buy.label"
                  defaultMessage="Barkauf"
                />
              }
              value="buy"
            />
            {ranges.has("credit") && (
              <Tab
                label={
                  <FormattedMessage
                    id="filter.financial.rateType.options.classicCarLoan.label"
                    defaultMessage="Kredit ohne Restzahlung"
                  />
                }
                value="credit"
              />
            )}
            {ranges.has("balloon") && (
              <Tab
                label={
                  <FormattedMessage id="filter.financial.rateType.options.balloonFinancing.label" />
                }
                value="balloon"
              />
            )}
            {ranges.has("leasing") && (
              <Tab
                label={
                  <FormattedMessage
                    id="filter.financial.rateType.options.leasingMileage.label"
                    defaultMessage="Leasing"
                  />
                }
                value="leasing"
              />
            )}
          </Tabs>
        </Grid>

        <Grid item xs={12} lg={4}>
          {ranges.has(filter.type) && (
            <>
              <RunTime
                min={ranges.get(filter.type).runtime[0]}
                max={ranges.get(filter.type).runtime[1]}
                value={filter.runTime}
                changeValue={changeValue}
              />
              <Deposit
                name="deposit"
                min={0}
                max={financeRate?.maxDeposit || vehicle.preis}
                step={tile(financeRate?.maxDeposit || vehicle.preis)}
                value={filter.deposit}
                changeValue={changeValue}
              />
              {ranges.get(filter.type)["mileage"] && (
                <Mileage
                  name="mileage"
                  min={ranges.get(filter.type).mileage[0]}
                  max={ranges.get(filter.type).mileage[1]}
                  value={filter.mileage}
                  changeValue={changeValue}
                />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} lg={5}>
          <Detail {...{ financeRate, vehicle }} />
        </Grid>
      </Grid>
    </Paper>
  );
};

const tile = (fullAmount: number = 999): number => {
  switch (true) {
    case fullAmount > 50000:
      return 5000;
    case fullAmount > 20000:
      return 2000;
    case fullAmount > 10000:
      return 1000;
    case fullAmount > 5000:
      return 500;
    case fullAmount > 2000:
      return 200;
    case fullAmount > 1000:
      return 100;
    case fullAmount > 500:
      return 50;
    case fullAmount > 200:
      return 20;
    default:
      return 10;
  }
};

export default Finance;
