import { enc } from "crypto-js";

export const encrypt = (plainText = "This is a text") => {
  var wordArray = enc.Utf8.parse(plainText);
  var base64 = enc.Base64.stringify(wordArray);
  return base64;
};

export const decrypt = (cipherText = "This is a text") => {
  var parsedWordArray = enc.Base64.parse(cipherText);
  var parsedStr = parsedWordArray.toString(enc.Utf8);
  return parsedStr;
};
