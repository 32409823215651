import React, { ReactNode, useReducer } from "react";

import TrackingReducer from "./TrackingReducer";

import { TrackingStoreType } from "../types";

type TTrackingProvider = {
  config?: { type: string };
  children: ReactNode;
};

const initialState: TrackingStoreType = [{}, () => {}];
const emptyStore: any = {};

export const TrackingStore = React.createContext<{
  state: any;
  dispatch: React.Dispatch<any>;
}>({
  state: emptyStore,
  dispatch: () => null,
});

const TrackingProvider = ({
  config = { type: "test" },
  children,
}: TTrackingProvider) => {
  const [state, dispatch] = useReducer(TrackingReducer, initialState[0]);
  React.useEffect(() => {
    dispatch({ type: "INIT", payload: {} });
  }, []);

  return (
    <TrackingStore.Provider value={{ state, dispatch }}>
      {children}
    </TrackingStore.Provider>
  );
};

export default TrackingProvider;
