import React, { useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface IFilter {
  title: any;
  component: any;
  open?: boolean;
  disabled?: boolean;
}

const Filter: React.FC<IFilter> = (
  { title, component, open = false, disabled = false },
  children
): JSX.Element => {
  const [expanded, setExpanded] = useState(open);
  return (
    <Accordion
      {...{ disabled, expanded: expanded && !disabled }}
      square
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{component}</AccordionDetails>
    </Accordion>
  );
};

export default Filter;
