import React from "react";
import { FormattedMessage } from "react-intl";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

import CloseIcon from "@mui/icons-material/Close";

import { Captcha } from "../../components";

interface IMFA {
  open: boolean;
  handleClose: any;
  handleSubmit: any;
}

const MFA: React.FC<IMFA> = ({
  open = true,
  handleClose,
  handleSubmit = () => true,
}): JSX.Element | null => {
  const [code, setCode] = React.useState<string>("");
  const [error, setError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [captcha, setCaptcha] = React.useState<boolean>(false);

  const submitHandler = async (event: any) => {
    event.preventDefault();
    if (loading) return;
    setError(false);
    setLoading(true);
    const result = await handleSubmit(code);
    if (result !== true) {
      setCaptcha(true);
      setError(true);
    }
    setLoading(false);
  };

  const closeHandler = async () => {
    setLoading(false);
    setError(false);

    handleClose();
  };

  const resolveCaptcha = (answer: boolean) => {
    setCaptcha(!answer);
  };

  return (
    <Dialog open={open} onClose={() => closeHandler()}>
      <DialogTitle>
        <FormattedMessage
          id="checkout.mfa.headline"
          defaultMessage="Authentifizierung"
        />
        <IconButton
          aria-label="close"
          onClick={() => closeHandler()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          <FormattedMessage
            id="checkout.mfa.description"
            defaultMessage="Bitte geben Sie den 6-stelligen Verifikations-Code ein, den Sie gerade per SMS zugesandt bekommen haben."
          />
        </DialogContentText>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "baseline",
          }}
        >
          {captcha && <Captcha resolveHandler={resolveCaptcha} />}
          {!captcha && (
            <form onSubmit={submitHandler}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <TextField
                  error={error}
                  autoFocus={false}
                  margin="dense"
                  inputProps={{ maxLength: 6 }}
                  id="code"
                  label="Code"
                  variant="standard"
                  onChange={(e) => setCode(e.target.value)}
                  helperText={
                    error ? (
                      <FormattedMessage
                        id="checkout.mfa.error"
                        defaultMessage="Falsche Eingabe."
                      />
                    ) : (
                      " "
                    )
                  }
                />
                <Button
                  disabled={code.length !== 6}
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{ ml: 2, mb: 3 }}
                >
                  Prüfen
                  {loading && (
                    <CircularProgress color="info" size="1em" sx={{ ml: 2 }} />
                  )}
                </Button>
              </Box>
            </form>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MFA;
