import React from "react";
import ReactGA from "react-ga4";

import { CookieStore } from "stores";

const GA_ID = process.env.REACT_APP_GA_ID;

const TrackingReducer = (state: any, action: { type: any; payload: any }) => {
  const { state: cookies } = React.useContext(CookieStore);

  const { type, payload = {} } = action;

  switch (type) {
    case "INIT":
      if (GA_ID) {
        try {
          const ga = ReactGA.initialize(GA_ID);
          return { ...state, ga };
        } catch (err) {
          console.log(err);
        }
      }
      return state;

    default:
      return state;
  }
};

export default TrackingReducer;
